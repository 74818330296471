body {
  background: rgb(58, 62, 65);
}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  padding: 0;
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  flex: 1;
  background-color: rgb(34, 36, 38);
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header .score {
  display: grid;
  gap: 2px;
  margin: 4px;
}

.App-link {
  color: #61dafb;
}

main {
  flex: 1;
  background: rgb(58, 62, 65);
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: 1rem 0;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.choices {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.choice {
  user-select: none;
  font-size: calc(10px + 1.5vmin);
  min-height: 3rem;
  background: none;
  color: white;
  border: none;
  min-width: 10rem;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;

  border-radius: 50px;
  background: linear-gradient(145deg, #2c3032, #34393b);
  box-shadow: 19px 19px 38px #2a2d2f, -19px -19px 38px #383d3f;
}

.choice:hover {
  background: rgb(34, 36, 38);
}

.choice-active {
  background: rgb(102, 126, 234);
}

.choice:active {
  background: rgb(75, 103, 231);
}

.message {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.message p {
  height: 3rem;
}

.message-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
}

.message-response {
  margin: 0 0 2rem 0;
  font-size: calc(10px + 1vmin);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  font-weight: 200;
}

.message-response div {
  display: flex;
  flex-flow: row;
}

.message-response span {
  margin-left: 3px;
  font-weight: 500;
  text-align: left;
}

.bad-answer {
  height: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  background: #ef4444;
  box-shadow: 7px 7px 15px #2b2e30, -7px -7px 15px #373c3e;
}

.good-answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  box-shadow: 7px 7px 15px #2b2e30, -7px -7px 15px #373c3e;

  background-color: #059669;
}

.good-answer .message-button-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.good-answer .message-button {
  background: none;
  border: none;
  box-shadow: none;
  border: none;
  color: white;
  font-size: calc(10px + 3vmin);
  padding: 0.3rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.next {
  border-radius: 50px;
  padding: 0.3rem;
  border-radius: 50px;
  cursor: pointer;

  background: #313537;
  box-shadow: inset 7px 7px 15px #2b2e30, inset -7px -7px 15px #373c3e;
}

.next:hover {
  animation: App-logo-spin 0.2s;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.response {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
}

.response input[type="text"] {
  font-size: calc(10px + 2vmin);
  padding: 0.5rem 1rem;
  border: none;

  color: white;
  border-radius: 25px 0 0 25px;
  background: #2b2e30;
  box-shadow: inset -20px 20px 41px #252729, inset 20px -20px 41px #313537;
}

.response input[type="submit"] {
  font-size: calc(10px + 2vmin);
  padding: 0.5rem 1rem;
  border: none;
  border-left: 1px solid rgb(58, 62, 65);
  border-radius: 0 25px 25px 0;

  color: white;
  background: #2b2e30;
  box-shadow: inset 8px 8px 16px #252729, inset -8px -8px 16px #313537;
}

.indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  height: 0.5rem;
  width: 50%;
  border-radius: 50px;
  margin: 1rem 0rem;
}

.go-menu {
  flex: 0;
}

.button-menu {
  user-select: none;
  font-size: calc(14px + 0.5vmin);
  min-height: 3rem;
  background: none;
  color: white;
  border: none;
  min-width: 10rem;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease;

  border-radius: 50px;
  background: #2b2e30;
  box-shadow: inset -20px 20px 41px #252729, inset 20px -20px 41px #313537;
}

.button-menu:hover {
  background: rgb(34, 36, 38);
  box-shadow: none;
}

.menu {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  padding-bottom: 5rem;
}

.menu > hr {
  width: 5rem;
  height: 4px;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border: none;
  border-radius: 50px;
}

.multiple-choices {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  border-radius: 50px;
  background: #222426;
  box-shadow: 16px 16px 32px #1c1d1f, -16px -16px 32px #282b2d;
}

.multiple-choice {
  padding: 1.5rem 3rem;
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.multiple-choice span:first-child {
  margin-right: 1rem;
}

.multiple-choice:first-child {
  border-radius: 50px 50px 0 0;
}
.multiple-choice:last-child {
  border-radius: 0 0 50px 50px;
}

.multiple-choice:hover {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.menu-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 5rem;
  padding: 1rem;
}

.menu-items > * {
  flex: 1;
}

.verbs-list {
  overflow: hidden;
  box-shadow: 16px 16px 32px #1c1d1f, -16px -16px 32px #282b2d;
  border-radius: 50px;
}

.verbs-list-inner {
  height: 50vh;
  overflow-y: scroll;

  background: #222426;
}

.verbs-list-inner::-webkit-scrollbar-track {
  border-radius: 0 50px 50px 0;
  background-color: #222426;
}

.verbs-list-inner::-webkit-scrollbar {
  width: 12px;
  border-radius: 0 50px 50px 0;
  background-color: #222426;
}

.verbs-list-inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

/** Reduce the size of the scrollbar */
.verbs-list-inner::-webkit-scrollbar,
.verbs-list-inner::-webkit-scrollbar-button,
.verbs-list-inner::-webkit-scrollbar-track,
.verbs-list-inner::-webkit-scrollbar-track-piece,
.verbs-list-inner::-webkit-scrollbar-thumb,
.verbs-list-inner::-webkit-scrollbar-corner,
.verbs-list-inner::-webkit-resizer {
  margin-top: 17px;
  margin-bottom: 17px;
}

.verb-item {
  user-select: none;
}

.verb-item-active {
  background: #3a3e41;
  user-select: none;
  padding: 0.3rem 1rem;
}

@media (min-width: 1000px) {
  main {
    flex-direction: row;
  }
  
  .content {
    height: 30rem;
  }

  .go-menu {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .choices {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, auto [col-start]);
    grid-gap: 1rem;
  }

  .good-answer, .bad-answer {
    width: 30rem;
    border-radius: 50px;
  }
}