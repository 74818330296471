@media screen and (max-width: 640px) {
  .menu {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    min-width: auto;
  }

  .menu-items {
    margin: 2rem auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    max-height: none;
    gap: 2rem;
  }

  .menu-items > * {
    margin: auto;
  }

  .multiple-choices {
    width: 80vw;
  }

  .verbs-list {
    flex: 1;
  }

  .go-menu {
    position: relative;
    left: 0;
  }

  .choices {
    display: flex;
    flex-flow: column;
  }

  .good-answer {
    width: 100vw;
  }

  .bad-answer {
    width: 100vw;
  }

  .content {
    height: auto;
  }

  .message {
    min-width: auto;
  }
}
